import { HeroSection, ContentSectionData } from 'types/domain/content';

// Main homepage hero content
export const heroContent: HeroSection = {
  title:
    'Powerful native extensions & plugins to enhance and extend the capabilities of your mobile and desktop applications.',
};

// Main intro text section
export const introContent: ContentSectionData = {
  title: 'Native extensions & plugins',
  items: [
    {
      title: 'Powerful extensions to enhance any application.',
      paragraphs: [
        'We specialise in developing a wide range of plugins and extensions for both mobile and desktop applications.',
        'For many platforms, low-level access to features and SDKs of operating systems and devices is not available to developers using standard development tools and frameworks. Most platforms offer ways to bridge this gap by supporting native extension or plugin code to be developed using native code and integrated with your applications via a custom API.',
      ],
      ctas: [
        {
          label: 'AIR Native Extensions',
          href: 'https://airnativeextensions.com',
          target: '_blank',
        },
        {
          label: 'Unity Store Plugins',
          href: 'https://assetstore.unity.com/publishers/46451',
          target: '_blank',
        },
      ],
    },
    {
      title: 'Productivity for developers',
      paragraphs: [
        'An added benefit of this approach, is we are able to provide a single API for use across multiple platform targets. For example, the exact same code can be implemented in an Adobe AIR or Unity application while supporting integration with both iOS and Android applications. This saves time and makes adding native features simple and easy for developers.',
      ],
    },
    {
      title: 'Multiple platforms, guaranteed support',
      paragraphs: [
        'We develop both commercial and custom plugins and extensions for Adobe AIR, Unity, Flutter and Haxe. With over 50 ANEs, we offer the widest range of Adobe AIR native extensions on the market today.',
      ],
    },
    {
      title: 'Custom extension development',
      paragraphs: [
        'Do you need a native extension created for a specific purpose or project?',
        "We offer custom development for any native extension or plugin you might need. We've developed hundreds of custom extensions for companies all over the world, to help them leverage existing native SDKs and features of many different platforms.",
      ],
      ctas: [
        {
          label: 'Contact us for more information',
          href: 'mailto:airnativeextensions@distriqt.com',
        },
      ],
    },
  ],
};
