import React from 'react';
import './_includes';
import HelmetHead from 'components/HelmetHead';
import PageContainer from 'containers/PageContainer';
import FullWidth from 'containers/FullWidthContainer';
import Section from 'containers/SectionContainer';
import HeroSection from 'components/HeroSection';
import ContentSection from 'components/ContentSection';
import bgImage from 'assets/images/promo-background-darker.jpg';
import { heroContent, introContent } from 'assets/content/native-extensions';

const NativeExtensionsPage: React.FC = (): JSX.Element => {
  return (
    <PageContainer>
      <HelmetHead
        title="distriqt - Native Extensions"
        url="https://distriqt.com/native-extensions"
      />
      <FullWidth backgroundImage={bgImage as string} useClipPath>
        <Section id="native-extensions">
          <HeroSection content={heroContent} />
        </Section>
      </FullWidth>
      <Section id="native-extensions-intro">
        <ContentSection content={introContent} showDivider={false} />
      </Section>
    </PageContainer>
  );
};

export default NativeExtensionsPage;
